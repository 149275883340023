@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/fontawesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
font-family: 'Roboto', sans-serif;
box-sizing: border-box;
}
a {
text-decoration: none !important;
}
.bg-grey {
background: #ebebeb
}
.ptb-70 {
padding: 50px 0px;
}
.pb-70 {
padding: 0 0 70px 0px;
}
.mt-30 {
margin-top: 30px !important;
}
.mt-15 {
margin-top: 15px !important;
}
.mt-20 {
margin-top: 20px !important;
}
.mt-10 {
margin-top: 10px !important;
}
/*********Header*****************/


.outer_header{

height: 500px;
}

.menu_icon{
  margin-top: 15px;
  height: 45px;
  cursor: pointer;
}

.custom-nav.navbar {
background: linear-gradient(288.68deg, #0026FF -33.06%, #0C1079 40.86%);
padding: 2px 10px 21px;
border-radius: 0 0 25px 25px;
}
@media (min-width: 601px)  {
  .custom-nav.navbar {
    padding: 49px 10px 24px;
  }
}
@media (min-width: 768px)  {
  .custom-nav.navbar {
    padding: 19px 10px 28px;
    border-radius: 0;
  }
}
.custom-nav.navbar .container {
  justify-content: center;
}
/* .custom-nav {
background: #000;
} */
.custom-nav .navbar-brand {
color: #fff;
margin-top: 2px;
}
.custom-nav .navbar-nav li span {
color: #fff;
cursor: pointer;
font-family: "GTWalsheimPro-Bold", sans-serif;
font-size: 17px;
font-weight: 700;
line-height: 17px;
background-color: transparent;
background-image: linear-gradient(180deg, #FF8A49 92%, #A23A00 100%);
border-radius: 7px;
padding: 17px 19px;
}

html {
    height: 100%;
    box-sizing: border-box;
  }

body {
    position: relative;
    margin: 0;
    padding-bottom: 482px;
    min-height: 100%;
    background-color: #F9FBFF;
  }
@media (min-width: 768px) {
  body {
    padding-bottom: 597px;
  }
}
@media (min-width: 992px) {
  body {
    padding-bottom: 509px;
  }
}


.form-label{
  font-family: 'GTWalsheimPro-Regular';
}

h3{
  font-family: 'GTWalsheimPro-Regular';
}

h4{
  font-family: 'GTWalsheimPro-Regular';
}


/*********Header Ends************/
.errorMsg { color: red; font-size: 12px; padding: 10px 4px; }
.button-after-video a,  .btn-primary, .submit-from
{ background:rgb(58, 81, 153)!important ; border: none !important;
font-size: 24px !important ;
font-weight: 600 !important ;
display: block; font-family: "GTWalsheimPro-Medium", sans-serif;}
.form-group h4 { margin-top: 20px; }
.player-wrapper {
position: relative;
padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.btn-primary:hover, .submit-from:hover { background:rgb(58, 81, 153) !important;
    box-shadow: inset 0 2px 2px 0 rgb(255 255 255 / 22%), 0 233px 233px 0 rgb(255 255 255 / 12%) inset; }

.react-player {
position: absolute;
top: 0;
left: 0;
}
.upload-file {
background: #ebf0ff;
color: #000 !important;
position: relative;
padding: 10px 20px;
font-weight: 500;
display: inline-block;
border-radius: 2px;
padding-left: 40px;
border: solid 1px #3a5199;
}
.upload-file::before {
content: '';
background: url(./upload-icon.png);
height: 24px;
width: 24px;
display: inline-block;
background-size: 100%;
position: absolute;
left: 10px;
top: 50%;
transform: translateY(-50%);
}

#loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}

#loading-image {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  left: 0;
  right: 0;
  margin: auto;
}
.loader-wrapper #loading {
  display: none;
}

.loader-wrapper .after-loading #loading {
  display: block;
}

.video-section {
    /* background: url(./wtxgdbg.jpg) no-repeat 0 0;
    background-size: cover; */
}
.video-section .heading h1 {
  font-family: "GTWalsheimPro-Medium", sans-serif;
  font-size: 28px;
  line-height: 1;
  color: #000;
}
.video-section .heading h1 b {
  font-family: "GTWalsheimPro-Medium", sans-serif;
}
.video-section p {
  font-family: "GTWalsheimPro-Regular", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
}
@media (min-width: 768px) {
  .video-section .heading h1 {
    font-size: 39px;
  }
  .video-section p {
    font-size: 17px;
    line-height: 25px;
  }
}
/* .back-link {
   font-size: 20px;  
   color: rgb(58, 81, 153);
   margin-top: 10px;
}
.back-link:hover {
  color: #rgb(58, 81, 153);
} */
.back-link svg#Layer {
  width: 30px;
  height: 30px;
}
.back-link svg#Layer {
  fill:rgb(58, 81, 153);
 }

.back-link:hover svg#Layer {
 fill: rgb(58, 81, 153);
}

.remove_button{
  color: #fff;
    cursor: pointer;
    font-family: "GTWalsheimPro-Bold", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 0;
    height: 35px;
    width: 35px;
    background-color: transparent;
    background-image: linear-gradient(
180deg
, #FF8A49 92%, #A23A00 100%);
    border-radius: 7px;
    padding-bottom: 5px;
    border: 0px;
    margin-top: 20px;
}


.add_button{
  margin-left:auto;
  margin-right:auto;
  display:block;
  height:35px;
  width:35px;
  line-height:0;
  border-radius:7px;
  background:#6FCF97;
  color: #fff;
    cursor: pointer;
    font-family: "GTWalsheimPro-Bold", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 0;
    height: 35px;
    width: 35px;
    border: 0;
}


.welcome_container{
  background: linear-gradient(288.68deg, #0026FF -33.06%, #0C1079 40.86%);
   margin-top:-140px; 
   position:relative;
}

.welcome_inner_container{
  position:absolute; 
  top:50%;
   transform:translateY(-50%); 
   left:0;
   right:0;
   text-align:center
}

.welcome_inner_header{
  font-family: GTWalsheimPro-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 115%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.welcome_inner_paragraph{
  font-family: GTWalsheimPro-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 155%;
  /* or 46px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF; 
  max-width:800px; 
  margin-left:auto; 
  margin-right:auto;
  margin-top:30px;
}

.welcome_inner_button{
  color: #fff;
  cursor: pointer;
  font-family: GTWalsheimPro-Regular;
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #FF8A49 92%, #A23A00 100%);
  border-radius: 7px;
  padding: 17px 19px; 
  margin-top:50px;
  min-width: 220px;
}

.welcome_inner_button_requested{
  color: #FF8A49;
  cursor: not-allowed;
  font-family: GTWalsheimPro-Regular;
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  background-color: transparent;
  border: 4px solid #FF8A49;
  border-radius: 10px;  padding: 17px 19px; 
  margin-top:50px;
  min-width: 220px;
}

.welcome_lines{
  width:100%;
  margin-top:180px;
  margin-bottom:180px;
}


/********Footer******************/
.footer .footer-block * {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
}

.footer {
padding: 20px 0px;
position: absolute;
right: 0;
bottom: 0;
left: 0;
background-color: #F9FBFF;
}
.footer-block img {
width: 80px;
}
.footer-block {
color: #888;
font-size: 14px;
}
.footer .footer-note {
  background-color: #FFFFFF;
  border-radius: 9px 9px 9px 9px;
  box-shadow: 0px 1px 20px -7px rgb(166 186 225 / 45%);
  max-width: 1053px;
  margin: 35px auto 38px;
}
.footer .footer-note p {
  font-family: 'GTWalsheimPro-Regular', sans-serif;
  font-size: 15px;
  line-height: 24.5px;
  padding: 5px;
  color: #000;
}
.footer .footer-note p span {
  color: #FF8A49;
}
.footer .footer-bottom-text {
  font-size: 14px;
  line-height: 24.5px;
  color: #888;
  margin-bottom: 5px;
}
/********Footer Ends*************/
/*******Login Page***************/

.login-section{
background: white;
  /* margin-top: -400px; */
  margin-bottom: 100px;
}

.login-section2{
  background: white;
    margin-top: -400px;
    margin-bottom: 100px;
  }

.login_heading{
  font-family: "GTWalsheimPro-Regular", sans-serif;
  font-style: normal;
font-weight: bold;
font-size: 50px;
line-height: 115%;
/* or 57px */

letter-spacing: -0.01em;

color: #FFFFFF;
}

.login_box_header{
  font-family: "GTWalsheimPro-Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 140.11%;
  color: #000000;
  margin-top: 25px;
}

.login_box_paragraph{
  font-family: "GTWalsheimPro-Regular", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 140.11%;
color: #000000;
opacity: 0.5;
}

.forgot_password{
  font-family: "GTWalsheimPro-Regular", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 140.11%;
color: #000000;
opacity: 0.6;
text-align: right;
display: inline-block;
margin-top: 30px;
}

.login_tb_label{
  font-family: "GTWalsheimPro-Regular", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 140.11%;
color: #000000;
opacity: 0.6;
}

.login_tb{
  border: none;
  border-bottom: 1px solid #f2f2f2;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  font-family: "GTWalsheimPro-Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140.11%;
  color: #000;
  background-clip: padding-box;
  padding-left:40px;
}

.login_tb:focus{
  outline: none;
}

.show_password_icon{
    width: 22px;
    position: absolute;
    right: 20px;
    top: 43.5px;
    cursor: pointer;
    z-index: 10;
}

.input_icon{
  width: 20px;
  position: absolute;
  left: 5px;
  top: 45px;
}

.login_button{

  background-image: linear-gradient(
    180deg
    , #FF8A49 92%, #A23A00 100%);border-radius: 10px;
border: 0px;
height: 70px;
margin-top: 40px;
color: white;
font-size: 24px;
font-weight: 700;
line-height: 17px;
font-family: "GTWalsheimPro-Bold", sans-serif;
}

.footer {
padding-top: 20px;
padding-bottom: 20px;
color: rgba(234, 234, 234, 0.4);
}
.footer .container{
padding-top: 20px;
padding-bottom: 55px;}

.footer-logo { 
  margin-bottom: 28px;
  height: 56px;
 }
.footer h3 {
margin-bottom: 18px;
font-weight: bold;
font-size: 24px;
color: rgb(136, 136, 136); }

.footer p { margin-bottom: 15px; font-size: 14px;  line-height: 20px;}

.footer p span { display: block; }

.login-box {
padding: 30px;
width: 600px;     
box-shadow: 0px 20px 20px 10px rgba(0, 0, 0, 0.20);
background: #fff;
font-family: "GTWalsheimPro-Regular", sans-serif;
max-width: 550px;
margin: auto;
border-radius: 6px;
}
footer.footer .col-md-4:last-child p {
color: rgb(110, 110, 110);
}
footer.footer {
padding-top: 50px;
padding-bottom: 50px;
font-family: sans-serif !important;
}
/*******Login Page Ends**********/
/********Form Page***************/
.login-box h3 {
font-size: 24px;
margin-bottom: 15px;
border-bottom: solid 1px #000;
padding-bottom: 15px;
}
.form-group h4 {
font-size: 16px;
margin-bottom: 15px;
font-weight: 600;
}
.form-grouping-custom .form-check {
display: inline-block;
}
.form-group .form-check+.form-check {
margin-left: 10px;
}
.add-link {
float: right;
background: #ddd;
border: 0;
outline: 0;
padding: 7px 30px;
border-radius: 3px;
}
.form-group {
font-size: 14px;
overflow: hidden;
margin-bottom: 0px !important;
}
.form-group input[type="email"], .form-group input[type="text"], .form-group input[type="password"], .form-group textarea {
height: 50px;
border: solid 1px #ccc;
border-radius: 2px;
padding: 6px 12px
}
.form-group textarea { height: 100px; resize: none; }
.form-grouping-custom .form-group h4 {
margin-top: 0;
margin-bottom: 8px;
font-size: 14px;
}
.errorMsg:empty {
display: none;
}
.form-grouping-custom .form-group {
margin-top: 20px;
}
.form-grouping-custom+.form-grouping-custom {
margin-top: 50px;
}
.form-grouping-custom h3 {
font-size: 20px;
line-height: 30px;
font-weight: 600;
margin: -20px;
margin-bottom: 0;
padding: 10px 20px;
background: #f5f5f5;
}

.form-grouping-custom .form-check input[type=radio] {
margin-left: -18px;
margin-top: 3px;
}
.form-grouping-custom {
border: solid 1px #ddd;
padding: 20px;
overflow: hidden;
border-radius: 3px;
}
.input-and-headings+.input-and-headings {
margin-top: 20px;
}
.seperator {
margin: 30px 0px;
display: block;
height: 1px;
border-top: solid 1px #ddd;
}
.input-and-headings-outline-inline {
display: flex;
flex-wrap: wrap;
align-items: center;
}
.input-and-headings-outline-inline .input-and-headings+.input-and-headings {
margin: 0;
margin-left: 20px;
margin-bottom: 10px;
}
.input-and-headings-outline-inline .input-and-headings {
width: 32%;
position: relative;
margin-bottom: 10px;
}

.input-and-headings-outline-inline .input-and-headings .remove-color {
  position: absolute;
    top: 45%;
    right: 0;
    height: 23.99px;
    width: 24px;
    text-align: center;
    line-height: 23.99px;
    background: #e7e7e7;
    color: #3a5199;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    cursor: pointer;
    transition: .3s !important;
    border: solid 1px #a6a6a6;
}

.input-and-headings-outline-inline .input-and-headings+.input-and-headings > span {
  padding-right: 27px;
}

.input-and-headings-outline-inline .input-and-headings .remove-color:hover {
  background: #dddddd;
}

.add-color-button {
  background: #3a5199;
  border: solid 1px #3a5199;
  color: #fff;
  padding: 8px 30px;
  border-radius: 2px;
  margin-left: 20px;
}

.all-overflow-inherit {
  overflow: inherit !important;
}

.all-overflow-inherit .form-group {
  overflow: inherit !important;
}

.css-19gs8ny-Popover input[type="text"] {
  padding: 0;
  height: auto;
  border-radius: 0;
}

.error-msg {
  font-size: 15px;
  background: #fff4e1;
  padding: 15px 10px;
  color: #dc8e00;
  border: solid 1px #dc8e00;
  border-radius: 3px;
}

.success-message {
  font-size: 15px;
  background: #e4ffe4;
  padding: 15px 10px;
  color: #008000;
  border: solid 1px #008000;
  border-radius: 3px;
}

.messages:empty {
  display: none;
}

.logo-back { display: none; }
.logo-back svg { margin: -1px 10px 0 0; }
.success-message + .logo-back {display: inline-block;
  margin-top: 20px;
  color: rgb(58, 81, 153);
}
.metting-section {
background-color: #f5f8fa;
 }
.body-fix {border: none;
width: 100%;
height: 862px;
padding: 0 0 50px 0;
}
body.hubspot .p-top-4 img#logo-landmark {
  display: none !important;
}

.meeting-heading h1 {
  text-align: center;
  font-size: 32px;
}
.meeting-heading p {
    margin-top: 15px;
    outline: none;
    text-align: center;
    color: rgb(58, 81, 153);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 20px;
}
.meeting-heading ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 20px;
}
.meeting-heading ul li {
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
  width: 25%;
}

/********Form Page Ends**********/
/********Vedio page**************/
/* .button-after-video a {
font-size: 24px;
font-weight: 500;
} */
.main-video iframe {
width: 100%;
}
/********Vedio page Ends*********/
/* update css */
.heading h1 {font-size: 38px;    color: rgb(45, 45, 45); padding-bottom: 10px;  }
.btn.focus, .btn:focus { box-shadow: none !important; }
/* End update css */
/*******Responsive***************/
/* @media (min-width:1700px) {
#root {
position: relative;
}
footer.footer {
position: absolute;
top: 100%;
width: 100%;
}
}
@media (min-width:2000px) {
#root {
position: initial;
}
footer.footer {
position: absolute;
top: 100%;
width: 100%;
}
} */
@media only screen and (max-width: 767px) {

  .welcome_container{
     margin-top:-170px; 
  }

  .welcome_lines{
    margin-top:200px; 
    margin-bottom: 200px;
 }

 .welcome_inner_header{
  font-size: 50px;
  line-height: 60px;
 }

 .welcome_inner_paragraph{
   font-size: 25px;
 }

 .welcome_inner_container {
  top: 60%;
  padding-left: 20px;
  padding-right: 20px;
}

.login-box {
width: 100%;
}
footer {
text-align: center;
}
.footer-block img {
width: 80px;
display: table;
margin-bottom: 30px;
}

.footer-block {
  text-align: left;
}

.main-video iframe {
height: auto;
width: 100%;
}
p.copyright-text.m-0.text-end {
text-align: center !important;
}
.button-after-video a {
font-size: 16px;
}

footer.footer {
  position: initial;
}

body {
  padding-bottom: 0;
}

.footer .container {
  padding-top: 0;
  padding-bottom: 0;
}

.button-after-video.mt-15 {
  margin-top: 0 !important;
}

.react-player {
  position: initial;
}

.player-wrapper {
  padding-top: 0;
}

.heading h1 {
  text-align: left;
  font-size: 24px;
  line-height: 34px;
}

.video-section.ptb-70 {
  padding-bottom: 30px;
}

.heading h1 a.back-link {
  display: table;
  margin-bottom: 20px;
  background: #3a5199;
  height: 45px;
  width: 45px;
  line-height: 42px;
  border-radius: 100%;
  text-align: center;
}

.back-link svg#Layer {
  fill: #fff;
  height: auto;
}

.form-grouping-custom h3 {
  font-size: 18px;
  line-height: 24px;
  padding: 15px 20px;
}

.input-and-headings-outline-inline .input-and-headings {
  width: 100%;
}

.input-and-headings-outline-inline .input-and-headings+.input-and-headings {
  margin-left: 0;
}

.add-color-button {
  margin-left: 0;
}
.meeting-heading h1 {
  text-align: left;
  font-size: 24px;
  line-height: 34px;
}
.meeting-heading p {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
}

.meeting-heading ul li { width: 100%;   
    text-align: left; }

}
@media only screen and (max-width: 480px) {
.login-box button[type="submit"] {
width: 100%;
}

.welcome_container{
  margin-top:-120px; 
}

.welcome_lines{
 margin-top:275px; 
 margin-bottom: 275px;
}

.welcome_inner_header{
font-size: 50px;
line-height: 60px;
}

.welcome_inner_paragraph{
font-size: 25px;
}

.welcome_inner_container {
top: 60%;
}
}