@font-face {
  font-family: "GTWalsheimPro-Black";
  src: local("GTWalsheimPro-Black"),
    url("./GTWalsheimPro-Black.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GTWalsheimPro-Medium";
  src: local("GTWalsheimPro-Medium"),
    url("./GTWalsheimPro-Medium.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "GTWalsheimPro-Regular";
  src: local("GTWalsheimPro-Regular"),
    url("./GTWalsheimPro-Regular.ttf") format("truetype");
  font-weight: normal;
}